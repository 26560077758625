<template>
  <div class="nav">
    <span>{{title}}</span>
    <van-icon v-if="$store.getters.getMenu" name="wap-nav" class="menu" @click="$emit('changeMenuShow',true)" />
  </div>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav{
  width: 100%;
  text-align: center;
  line-height: 44px;
  background: #ffffff;
  margin-bottom: 10px;
  box-shadow: 0px 6px 12px 0px rgba(105, 123, 140, 0.1);
  position: relative;
  span{
    font-size: 18px;
    font-weight: bold;
  }
  .menu{
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 11px;
  }
}
</style>
