import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const userId=sessionStorage.getItem("userId")?JSON.parse(sessionStorage.getItem("userId")):""
export default new Vuex.Store({
  state: {
    userId:userId,
    setMenu:false
  },
  getters:{
    getUserId(state){
      return state.userId
    },
    getMenu(state){
      return state.setMenu
    }
  },
  mutations: {
    SAVEUSERID(state,val){
      state.userId=val
    }
  },
  actions: {
    COMMITUSERID({commit},data){
      commit('SAVEUSERID', data);
    }
  },
  modules: {
  }
})
