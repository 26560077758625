import http from "./fetch"
import store from "./store/index"
//判断是否是微信浏览器
export function isWx() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}
//base64转文件
export function convertBase64UrlToImgFile(urlData, fileName) {
    let arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });

}
//获取地址栏参数
export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}
//企业微信浏览器内获取userId
export function handleInWxGetUserId(callback) {
    const redirectUrl = window.location.href;
    const code = getQueryVariable("code");
    const userId = sessionStorage.getItem("userId")
        ? JSON.parse(sessionStorage.getItem("userId"))
        : "";
    if (code && !userId) {
        http
            .get("/front/qywx/contract/login", { code,appTypeStr:"contract"})
            .then((res) => {
                if (res.code == 1 && res.data) {
                    sessionStorage.setItem("userId", JSON.stringify(res.data.id));
                    store.dispatch("COMMITUSERID",res.data.id)
                    callback()
                }
            })
            .catch((err) => {
                console.log(err);
            });
    } else if (!code && !userId) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww942333df160d71df&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
    }else{
        callback()
    }
}