<template>
  <van-popup
    class="menu"
    :value="visible"
    position="right"
    style="height: 100%"
    @click-overlay="$emit('update:visible', false)"
  >
    <img class="menu-logo" src="/logo.png" alt="" />
    <ul class="menu-list">
      <li :class="['menu-list-item',$route.path==o.path?'menu-list-active-item':'']" v-for="(o, i) in menus" :key="i">
        <van-icon :name="o.icon" size="1.2em" />
        <span @click="handleGoPath(o.path)">{{ o.name }}</span>
      </li>
    </ul>
  </van-popup>
</template>

<script>
export default {
  name: "Menu",
  props: {
    visible: Boolean,
  },
  data() {
    return {
      menus: [
        { name: "合同审批列表", path: "/contract-list", icon: "column" },
        { name: "合同财务审批", path: "/finance-list", icon: "bill" },
        { name: "留学合同财务审批", path: "/abroad-finance-list", icon: "bill" },
      ],
    };
  },
  methods: {
    handleGoPath(path) {
      this.$emit("update:visible", false);
      this.$router.push(path);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.menu {
  padding: 20px 0;
  &-logo {
    display: block;
    height: 40px;
    margin: 0 20px;
    margin-bottom: 40px;
  }
  &-list {
    width: 100%;
    &-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      padding: 15px 0;
      padding-left: 20px;
      position: relative;
      color: #4a4a4a;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: #ffffff;
      }
    }
    &-active-item {
      background: rgba(117, 150, 240, 0.2);
      color: #09205f;
      &::before {
        background: #09205f;
      }
    }
  }
}
</style>
