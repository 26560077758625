<template>
  <div id="app">
    <van-sticky>
      <Nav :title="$route.meta.title" @changeMenuShow="(val)=>{showMenu=val}"></Nav>
    </van-sticky>
    <router-view></router-view>
    <Menu :visible.sync="showMenu"></Menu>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Menu from "@/components/Menu.vue";

export default {
  name: "app",
  components: {Nav,Menu},
  data() {
    return {
      showMenu:false
    }
  },
};
</script>

<style lang="less" scoped>
</style>
