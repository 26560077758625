import Vue from 'vue'
import VueRouter from 'vue-router'
import { Toast } from "vant"
import { handleInWxGetUserId } from "../tool"


Vue.use(VueRouter)

const routes = [
  {
    path: '/contract-approve',
    name: 'ContractApprove',
    meta: { title: "合同审批" },
    component: () => import('../views/ContractApprove.vue')
  },
  {
    path: '/contract-finance',
    name: 'ContractFinance',
    meta: { title: "合同财务审批" },
    component: () => import('../views/ContractFinance.vue')
  },
  {
    path: '/abroad-contract-finance',
    name: 'abroadContractFinance',
    meta: { title: "留学合同财务审批" },
    component: () => import('../views/abroadContractFinance.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})
router.beforeEach((to, from, next) => {
  handleInWxGetUserId(() => {
    const title = to.meta.title||"菠萝在线"
    document.title = title
    Toast.clear()
    next()
  })
})
export default router
